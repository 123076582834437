<template>

    <div class="row">
        <label for="name" class="col-sm-4 required-label">{{ $t('form.first_name') }}</label>
        <div class="col-sm-8">
            <input v-model="user.first_name" type="text" id="fname" :disabled="isEditing" required />
        </div>
    </div>
    <div class="row">
        <label for="name" class="col-sm-4 required-label">{{ $t('form.last_name') }}</label>
        <div class="col-sm-8">
            <input v-model="user.last_name" type="text" id="lname" :disabled="isEditing" required />
        </div>
    </div>
    <div class="row">
        <label for="email" class="col-sm-4 required-label">{{ $t('form.email') }}</label>
        <div class="col-sm-8">
            <input v-model="user.email" type="email" id="email" :disabled="isEditing" required />
        </div>
    </div>
    <div class="row">
        <label for="ibeohr" class="col-sm-4 required-label">IBEO HR?</label>
        <div class="col-sm-8">
            <input v-model="user.is_hr" type="checkbox" id="ibeohr" :true-value="1" :false-value="0" />
        </div>
    </div>
    <div class="row">
        <label for="name" class="col-sm-4 required-label">{{ $t( isEditing ? 'form.password_new' : 'form.password') }}</label>
        <div class="col-sm-8">
            <input v-model="user.password" type="password" id="pwd" :required="!isEditing" />
        </div>
    </div>
    <br />
    <div class="row">
        <label class="col-sm-4 required-label">{{ $tc('navigation.customers', 2) }}</label>
        <div class="col-sm-8">
            <div v-for="customer, index in user.customers" :key="index" class="d-flex">
                <select class="w-75" v-model="customer.id" required @change="$event.target.classList.remove('is-invalid')">
                    <option :value="null">{{ $t('form.select') }}</option>
                    <option v-for="option in customers" :key="option.id" :value="option.id">{{ option.name }}</option>
                </select>
                <button type="button" class="btn" @click="removeCustomer(index)">
                    <i class="fa fa-close text-danger"></i>
                </button>
            </div>
            <button type="button" class="btn btn-success" @click="addCustomer">{{ $t('form.add', [$t('navigation.customers')]) }}</button>
        </div>
    </div>


</template>

<script>
    import customerService from '@/services/CustomerService.js'
    
    export default {
        name: 'UserForm',
        props: ['itemData'],
        data() {
            return {
                newUser: "",
                user: {
                    "first_name": "",
                    "last_name": "",
                    "email": "",
                    "password": "",
                    "is_hr": 0,
                    "customers": []
                },
                submitted: false,
                loading: false,
                invalid: {},
                isEditing: false,
                customers: []
            }
        },
        watch: {
            itemData: function (val) {
                this.user = val    
            },
        },
        methods: {
            validate(){
                const required = document.querySelectorAll('input[required], select[required]');
                let isValid = true;
                required.forEach(field => {
                    if(!field.value || field.value == this.$t('form.select')){
                        isValid = false;
                        field.classList.add('is-invalid');
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                });
                if(isValid){
                    this.user.name = this.user.first_name + ' ' + this.user.last_name;
                }
                return isValid;
            },
            getData() {
                return this.user;
            },
            setData(data) {
                this.user = data;
                console.log("Setting data and editing");
                this.isEditing = true;
            },
            
            reset(shouldLoadData = true) {
                this.isEditing = false;
                this.user = {
                    "first_name": "",
                    "last_name": "",
                    "email": "",
                    "password": "",
                    "is_hr": false
                }
                this.newUser = ""
                this.loading = false
                this.invalid = {}
                if(shouldLoadData && this.customers.length == 0){
                    this.getCustomers();
                }
            },
            addCustomer(){
                this.user.customers.push({
                    'id': null,
                    'name': null
                });
            },
            removeCustomer(index){
                this.user.customers.splice(index, 1);
            },
            getCustomers(){
                customerService.index().then(response => {
                    this.customers = response.data;
                }).catch(error => {
                    console.log('error', error)
                });
            }
        },
        mounted() {
            this.reset(false);
        }
    }
</script>