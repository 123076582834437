<template>
    <Overview 
        :moduleName="$tc('navigation.' + this.$route.name, 1)"
        :moduleNamePlural="$tc('navigation.' + this.$route.name, 2)"
        :sort="'last_name'"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :showCopy="false"
        ref="overview"
    >
        <template #columns>
            <Column field="first_name" :header="$t('form.first_name')" :sortable="true"></Column>
            <Column field="last_name" :header="$t('form.last_name')" :sortable="true"></Column>
            <Column field="email" :header="$t('form.email')" :sortable="true"></Column>
            <Column field="is_hr" header="IBEO HR?" :sortable="true" style="width: 10%">
                <template #body="slotProps">
                    <i class="fa fa-check text-success" v-show="slotProps.data.is_hr"></i>
                </template>
            </Column>
        </template>

        <template #form>
            <UserForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <!--<button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;Volgende<span id="nextStep">2</span></button>-->
        </template>
    </Overview>
  <div class="footer-buttons mb-2">
    <button type="button" class="btn action-button" data-bs-toggle="modal" data-bs-target="#formModal" @click="$refs.overview.addItem()">{{ $t('form.add', [$tc('navigation.' + this.$route.name, 1)]) }}</button>
  </div>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import UserForm from '@/components/forms/UserForm.vue';
import Column from 'primevue/column';
import userService from '@/services/UserService';

export default {
    name: 'Users',
    components: {
        Overview,
        Column,
        UserForm
    },
    data() {
        return {
            item: {},
            itemFormRef: null,
            itemService: userService,
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#formModal ul.nav-tabs .nav-link");
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        if(!store.getters.getIsHR){
            this.$router.push({
                name: "Home"
            });
        }
    }
}
</script>